import { AggregateFunction } from "../enum/aggregate-function";

export class BarSettings {
  aggregateFunction!: AggregateFunction;
  x!: BarSerie;
  y!: BarSerie[];
}

export class BarSerie {
  name!: string;
  field!: string;
}