export enum DataType {
  Number = "NUMBER",
  String = "STRING",
  Date = "DATE",
}

export const dataTypeList = [
  { value: DataType.Number, label: "Number" },
  { value: DataType.String, label: "String" },
  { value: DataType.Date, label: "Date" },
];
