import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import { useState } from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib";
import { FilterType, filterTypeList } from "@/models/enum/filter-type";
import { uuidv4 } from "@/utils";
import { DashboardFilter } from "./filter";
import { Filter } from "@/models/dashboard";

export function DeleteFilterIcon(props: { filter: Filter }) {
  const [visible, setVisible] = useState(false);

  const onDelete = () => {
    dashboardService.deleteFilter(props.filter.id);
  };

  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  return (
    <Popconfirm
      title="Delete filter"
      description="Are you sure to delete filter ?"
      open={visible}
      onConfirm={onDelete}
      onCancel={hide}
      okText="Yes"
      cancelText="No"
    >
      <Button
        icon={<DeleteOutlined />}
        size="small"
        shape="circle"
        type="text"
        danger
        onClick={show}
      />
    </Popconfirm>
  );
}

export function AddFilterButton() {
  const [filterFormVisible, setFilterFormVisible] = useState(false);

  const showFilterFormModal = () => {
    setFilterFormVisible(true);
  };

  const hideFilterFormModal = () => {
    setFilterFormVisible(false);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={showFilterFormModal}
        block
      >
        Add Filter
      </Button>
      {filterFormVisible &&
        <FilterForm
          onFinish={hideFilterFormModal}
        />
      }
    </>
  );
}

export function FilterForm(props: {
  filter?: Filter;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dashboard = useObservable(dashboardService.dashboard);
  const [type, setType] = useState(props.filter?.type);
  const [customOptions, setCustomOptions] = useState(props.filter?.customOptions ?? false);
  const [customInitialValue, setCustomInitialValue] = useState(props.filter?.customOptions ?? false);
  const [multiValue, setMultiValue] = useState(props.filter?.multiValue ?? false);
  const [options, setOptions] = useState<string[] | string | undefined>(props.filter?.options);
  const [preview, setPreview] = useState<Filter | undefined>();
  const isTesting = useObservable(dashboardService.isTestingFilter);
  const isSaving = useObservable(dashboardService.isSavingFilter);

  const getFiltersOptions = () => {
    return dashboard?.dataset.filters.map((filter) => {
      return { label: filter, value: filter };
    });
  }

  const getFieldsOptions = () => {
    return dashboard?.dataset.fields.map((field) => {
      return { label: field, value: "{" + field + "}" };
    });
  }

  const getInitialValueOptions = () => {
    return [{
      label: "First",
      value: "{FIRST}"
    },
    {
      label: "Last",
      value: "{LAST}"
    },
    ...multiValue ? [{
      label: "All",
      value: "{ALL}"
    }] : []
    ];
  }

  const onSubmit = (values: Filter) => {
    const filter = Object.assign(new Filter(), props.filter, values);
    if (!props.filter) {
      filter.id = uuidv4();
      dashboardService.addFilter(filter).then((result) => {
        if (result) {
          onCancel();
        }
      });
    } else {
      dashboardService.updateFilter(filter).then((result) => {
        if (result) {
          onCancel();
        }
      });
    }
  };

  const onCancel = () => {
    props.onFinish();
  };

  const onTest = () => {
    form.validateFields().then((values) => {
      const filter = Object.assign(new Filter(), values);
      dashboardService.testFilter(filter).then((value) => {
        setPreview(value);
      });
    }, () => { });
  };

  const onChangeType = (value: FilterType) => {
    setType(value);
    form.setFieldValue("customOptions", false);
    onChangeCustomOption(false);
    form.setFieldValue("multiValue", false);
    onChangeMultiValue(false);
  }

  const onChangeCustomOptionEvent = (e: CheckboxChangeEvent) => {
    onChangeCustomOption(e.target.checked);
  }

  const onChangeCustomOption = (value: boolean) => {
    setCustomOptions(value);
    form.setFieldValue("options", undefined);
    onChangeOptions(undefined);
    form.setFieldValue("initialValue", undefined);
    if (!value) {
      form.setFieldValue("customInitialValue", false);
      setCustomInitialValue(false);
    }
  }

  const onChangeOptions = (value?: string[] | string) => {
    setOptions(value);
  }

  const onChangeCustomInitialValueEvent = (e: CheckboxChangeEvent) => {
    onChangeCustomInitialValue(e.target.checked);
  }

  const onChangeCustomInitialValue = (value: boolean) => {
    setCustomInitialValue(value);
    form.setFieldValue("initialValue", undefined);
  }

  const onChangeMultiValueEvent = (e: CheckboxChangeEvent) => {
    onChangeMultiValue(e.target.checked);
  }

  const onChangeMultiValue = (value: boolean) => {
    setMultiValue(value);
    if (customInitialValue || form.getFieldValue("initialValue") === "{ALL}") {
      form.setFieldValue("initialValue", undefined);
    }
  }

  const getCustomOptions = () => {
    if (options && Array.isArray(options)) {
      return options?.map((option) => { return { label: option, value: option } });
    }
    return [];
  }

  return (
    <Modal
      open
      onCancel={props.onFinish}
      title={props.filter ? "Edit Filter" : "New Filter"}
      footer={[
        <Button key="test" onClick={onTest}>
          Test
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={isSaving}
        >
          {t("modal.new.submit")}
        </Button>
      ]}
    >
      <Form labelCol={{ span: 8 }} form={form} onFinish={onSubmit}>
        <Form.Item
          initialValue={props.filter?.name}
          labelAlign="left"
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={props.filter?.field}
          labelAlign="left"
          label="Field"
          name="field"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            options={getFiltersOptions()}
            style={{ width: "100%" }}
            allowClear
          />
        </Form.Item>
        <Form.Item
          initialValue={props.filter?.type}
          labelAlign="left"
          label="Type"
          name="type"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Select
            options={filterTypeList}
            style={{ width: "100%" }}
            allowClear
            onChange={onChangeType}
          />
        </Form.Item>
        {type === "SELECT" &&
          <>
            <Form.Item
              initialValue={props.filter?.customOptions}
              labelAlign="left"
              label="Custom Options"
              name="customOptions"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeCustomOptionEvent} />
            </Form.Item>
            <Form.Item
              initialValue={props.filter?.options}
              labelAlign="left"
              label="Options"
              name="options"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
            >
              <Select
                options={customOptions ? undefined : getFieldsOptions()}
                mode={customOptions ? "tags" : undefined}
                allowClear
                open={customOptions ? false : undefined}
                suffixIcon={customOptions ? null : undefined}
                style={{ width: "100%" }}
                onChange={onChangeOptions}
              />
            </Form.Item>
            <Form.Item
              initialValue={props.filter?.multiValue}
              labelAlign="left"
              label="Multi Value"
              name="multiValue"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeMultiValueEvent} />
            </Form.Item>
            <Form.Item
              initialValue={props.filter?.customInitialValue}
              labelAlign="left"
              label="Custom Initial Value"
              name="customInitialValue"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
              valuePropName="checked"
            >
              <Checkbox onChange={onChangeCustomInitialValueEvent} disabled={!customOptions} />
            </Form.Item>
          </>
        }
        <Form.Item
          initialValue={props.filter?.initialValue}
          labelAlign="left"
          label="Initial Value"
          name="initialValue"
        >
          {type === FilterType.INPUT ?
            <Input />
            :
            <Select
              options={customOptions && customInitialValue ? getCustomOptions() : getInitialValueOptions()}
              mode={customOptions && customInitialValue && multiValue ? "multiple" : undefined}
              allowClear
              style={{ width: "100%" }}
            />
          }
        </Form.Item>
      </Form>

      {(isTesting || preview) &&
        <Card title="Preview" >
          {isTesting ?
            <Loader /> :
            <DashboardFilter
              filter={preview!!}
              isTest
            />
          }
        </Card>
      }
    </Modal >
  );
}