import { Button, Checkbox, Form, Input, Space, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { authService } from "@/services";
import logo from "@/logo.svg";
import {
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import "./index.less";
import { useObservable } from "@/utils/use-observable";

export default function LoginPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const mfaRequired = useObservable(authService.isMFAOtpRequired);

  const onFinish = (values: {
    email: string;
    password: string;
    remember: boolean;
    mfaOtp?: string;
  }) => {
    setLoading(true);

    setLoading(false);
    authService
      .login(values.email, values.password, values.remember, values.mfaOtp)
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-page">
      <div>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <Typography.Title level={1} className="title">{t("login.title")}</Typography.Title>
        <Typography.Title level={5} className="subtitle">{t("login.credit")}</Typography.Title>
        <div className="fixed-width-page">
          <div className="content">
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label={t("login.email")} name="email" hidden={mfaRequired}>
                <Input prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item label={t("login.password")} name="password" hidden={mfaRequired}>
                <Input.Password prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item
                name="remember"
                valuePropName="checked"
                initialValue={true}
                hidden={mfaRequired}
              >
                <Checkbox>{t("login.rememberMe")}</Checkbox>
              </Form.Item>
              {mfaRequired && <Space direction="vertical" style={{ "marginBottom": "20px" }}>
                <Typography.Text>Your account is protected with <Typography.Text strong>multi-factor authentication (MFA)</Typography.Text>.</Typography.Text>
                <Typography.Text>To finish signing in, enter the otp from your MFA device below.</Typography.Text>
              </Space>}
              <Form.Item label={t("login.mfaOtp")} name="mfaOtp" required={mfaRequired} hidden={!mfaRequired}>
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  {t("login.submit")}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Text><Link to="/reset-password">{t("login.forgotPassword")}</Link></Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
}
