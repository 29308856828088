import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "@/services";
import useQueryParams from "@/utils/use-query-params";
import logo from "@/logo.svg";
import "./index.less";
import { useTranslation } from "react-i18next";
import { t as tr } from "i18next";
import { PasswordInput } from "@/utils/password-input-strenght";

export default function ChangePasswordPage() {
  const router = useNavigate();
  const query = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [t] = useTranslation();

  useEffect(() => {
    const qt = query.get("token");
    if (qt) {
      setToken(qt);
    } else {
      message.error(tr("changePassword.error.notLogged"), 4, () => {
        router("/login");
      });
    }
  }, [router, query]);

  const onFinish = (values: { newPassword: string }) => {
    setLoading(true);
    authService.newPassword(token!!, values.newPassword).then((response) => {
      setLoading(false);
      if (response) {
        message.success(t("changePassword.success"), 4, () => {
          router("/login");
        });
      }
    });
  };

  return (
    <div className="change-password-page">
      <div>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <h2 className="title">{t("changePassword.title")}</h2>
        <div className="fixed-width-page">
          <div className="content">
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                label={t("changePassword.newPassword")}
                name="newPassword"
                validateTrigger="onBlur"
                validateFirst={true}
                rules={[{
                  validator: (_, value) => {
                    return !!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g) ? Promise.resolve() : Promise.reject(new Error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"));
                  }
                }]}
              >
                <PasswordInput />
              </Form.Item>
              <Form.Item
                label={t("changePassword.confirmNewPassword")}
                name="confirmNewPassword"
                dependencies={["newPassword"]}
                rules={[
                  { required: true, message: t("error.fieldRequired") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t("changePassword.error.passwordNotMatch")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  loading={loading}
                  htmlType="submit"
                >
                  {t("changePassword.submit")}
                </Button>
              </Form.Item>
            </Form>
            <div className="row-link">
              {t("changePassword.doNotNeedChange")} <Link to="/login">{t("changePassword.signIn")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
