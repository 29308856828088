import { Button, Dropdown, Empty, Row, Table, Typography } from "antd";
import { WidgetType } from "@/models/enum/widget-types";
import { useRef, useState } from "react";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import "./widget.less";
import { ColumnsType } from "antd/lib/table";
import { useHeight } from "@/utils/use-dimension";
import Loader from "@/app/components/loader";
import { CSVLink } from "react-csv"
import { Widget } from "@/models/dashboard";
import { LineChart, CartesianGrid, Legend, Line, ResponsiveContainer, XAxis, YAxis, Tooltip, BarChart, Bar, PieChart, Pie, Sector, Cell } from "recharts";
import { BarSettings } from "@/models/widget-settings/bar-settings";
import { PieSettings } from "@/models/widget-settings/pie-settings";
import { LineSettings } from "@/models/widget-settings/line-settings";
import { TableSettings } from "@/models/widget-settings/table-settings";
import { renderAmountForChart, renderAmountForYAsix } from "@/utils";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

const { Title, Text } = Typography;
const colors = ["#8884d8", "#83a6ed", "#8dd1e1", "#82ca9d", "#a4de6c", "#d0ed57", "#ffc698", "#FFBA08"];

export function WidgetCard(props: {
  widget: Widget;
  isTest?: boolean;
  onDelete?: (widget: Widget) => void;
  onEdit?: (widget: Widget) => void;
}) {
  const isEditing = useObservable(dashboardService.isEditing);
  const isFetching = useObservable(dashboardService.isFetchingValues);

  const dropdownMenu = [
    {
      key: "edit",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: () => props.onEdit ? props.onEdit(props.widget) : undefined,
    },
    {
      key: "delete",
      label: "Delete",
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => props.onDelete ? props.onDelete(props.widget) : undefined,
    }
  ];

  const getWidget = () => {
    if (!props.widget.value || isFetching) {
      return <Loader />;
    }

    if (Array.isArray(props.widget.value) && props.widget.value.length === 0) {
      return <Empty />;
    }

    switch (props.widget.type) {
      case WidgetType.Card:
        return <CardChart value={props.widget.value} />;
      case WidgetType.Line:
        return <NewLineChart value={props.widget.value} settings={props.widget.parsedSettings} />;
      case WidgetType.Pie:
        return <NewPieChart value={props.widget.value} settings={props.widget.parsedSettings} />;
      case WidgetType.Bar:
        return <NewBarChart value={props.widget.value} settings={props.widget.parsedSettings} />;
      case WidgetType.Table:
        return <TableChart value={props.widget.value} settings={props.widget.parsedSettings} />
    }
  };

  return (
    <div className={"widget-container " + (props.isTest ? "test" : "")}>
      {!props.isTest && isEditing && (
        <div className="settings">
          <Button
            icon={
              <Dropdown menu={{ items: dropdownMenu }} placement="bottom" arrow trigger={["click"]} >
                <MoreOutlined
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
              </Dropdown>
            }
            type="text"
            size="small"
            shape="circle"
          />
        </div>
      )}
      <div className="header">
        <WidgetTitle title={props.widget.name} />
      </div>
      <div className="body">{getWidget()}</div>
    </div>
  );
}

function WidgetTitle(props: { title: string }) {
  return <Title level={4}>{props.title}</Title>;
}

function CardChart(props: { value: any }) {
  return <Text style={{ fontSize: "25px" }}>{props.value}</Text>;
}

function NewLineChart(props: { value: any, settings: LineSettings }) {
  const [opacity, setOpacity] = useState(props.settings.y.reduce((acc: any, curr) => {
    acc[curr.name] = 1;
    return acc;
  }, {}));

  const handleMouseEnter = (value: any) => {
    setOpacity((op: any) => ({ ...op, [value.dataKey]: 0.5 }));
  };

  const handleMouseLeave = (value: any) => {
    setOpacity((op: any) => ({ ...op, [value.dataKey]: 1 }));
  };

  const formatTooltip = (value: number | undefined, name: string, values: Payload<number, string>) => [renderAmountForChart(values.value), values.dataKey];

  return <ResponsiveContainer width="100%" height="100%">
    <LineChart
      data={props.value}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Data" />
      <YAxis tickFormatter={(value: number) => renderAmountForYAsix(value)} />
      {props.settings.y.map((serie, index) => <Line key={serie.name} type="monotone" dataKey={serie.name} strokeOpacity={opacity[serie.name]} stroke={colors[index % colors.length]} activeDot={{ r: 6 }} />)}
      <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      <Tooltip formatter={formatTooltip} />
    </LineChart>
  </ResponsiveContainer>
}

function NewBarChart(props: { value: any, settings: BarSettings }) {
  const [opacity, setOpacity] = useState(props.settings.y.reduce((acc: any, curr) => {
    acc[curr.name] = 1;
    return acc;
  }, {}));

  const handleMouseEnter = (value: any) => {
    setOpacity((op: any) => ({ ...op, [value.dataKey]: 0.5 }));
  };

  const handleMouseLeave = (value: any) => {
    setOpacity((op: any) => ({ ...op, [value.dataKey]: 1 }));
  };

  const formatTooltip = (value: number | undefined, name: string, values: Payload<number, string>) => [renderAmountForChart(values.value), values.dataKey];

  return <ResponsiveContainer width="100%" height="100%">
    <BarChart
      data={props.value}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.settings.x.name} tickFormatter={(value: number) => renderAmountForYAsix(value)} />
      <YAxis />
      {props.settings.y.map((serie, index) => <Bar key={serie.name} type="monotone" dataKey={serie.name} fillOpacity={opacity[serie.name]} fill={colors[index % colors.length]} />)}
      <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      <Tooltip formatter={formatTooltip} />
    </BarChart>
  </ResponsiveContainer>
}

function NewPieChart(props: { value: any, settings: PieSettings }) {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload }: any) => {
    // const RADIAN = Math.PI / 180;
    // const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = values;
    // const sin = Math.sin(-RADIAN * midAngle!);
    // const cos = Math.cos(-RADIAN * midAngle!);
    // const sx = cx! + (outerRadius! + 10) * cos;
    // const sy = cy! + (outerRadius! + 10) * sin;
    // const mx = cx! + (outerRadius! + 30) * cos;
    // const my = cy! + (outerRadius! + 30) * sin;
    // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    // const ey = my;
    // const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius! + 6}
          outerRadius={outerRadius! + 10}
          fill={fill}
        />
        {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${values[props.settings.key.name]}: ${value}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent! * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  };

  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  }

  const formatTooltip = (value: number | undefined, name: string, values: Payload<number, string>) => [renderAmountForChart(values.payload[props.settings.value.name]), values.payload[props.settings.key.name]];

  return <ResponsiveContainer width="100%" height="100%">
    <PieChart>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        dataKey={props.settings.value.name}
        data={props.value.filter((val: any) => val[props.settings.value.name] > 0)}
        onMouseEnter={onPieEnter}
        labelLine={false}
        paddingAngle={1}
      >
        {props.value.map((_: any, index: number) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      <Tooltip formatter={formatTooltip} />
    </PieChart>
  </ResponsiveContainer>;
}

function TableChart(props: { value: any, settings: TableSettings; }) {
  const divRef = useRef(null);
  const height = useHeight(divRef);
  const innerColumns = props.settings.columns.filter(col => !!col.nested);

  const computeColumns = () => {
    return props.settings.columns.filter(col => !col.nested).map(col => ({
      title: col.name,
      key: col.name,
      dataIndex: col.name,
      width: col.width,
      align: col.align,
      sorter: col.sortable ? (a, b) => {
        const keyA = a[col.name].toString();
        const keyB = b[col.name].toString();

        if (keyA.length !== keyB.length) {
          return keyA.length - keyB.length;
        }
        for (let i = 0; i < keyA.length; i++) {
          const compare = keyA[i].localeCompare(keyB[i], undefined, { numeric: true })
          if (compare !== 0) {
            return compare;
          }
        }
        return 0;
      } : undefined
    })) as ColumnsType<any>
  }

  const computeExportHeaders = () => {
    return props.settings.columns.map(col => ({
      label: col.name,
      key: col.name
    }))
  }

  const expandedRowRender = (record: any) => {
    if (innerColumns.length === 0) return null;

    return (
      <div className="expanded-row-block">
        {
          innerColumns
            .map(col => {
              return <><Title level={5}>
                {col.name}
              </Title>
                <Row>
                  {record[col.name] === "" ? "-" : record[col.name]}
                </Row>
              </>
            })
        }
      </div>
    );
  };

  return (
    <div ref={divRef} style={{ width: "100%", height: "100%" }}>
      <CSVLink
        className="export-button"
        filename={"export_table.csv"}
        headers={computeExportHeaders()}
        data={props.value}
      >
        Export to CSV
      </CSVLink>
      <Table
        columns={computeColumns()}
        dataSource={props.value}
        rowKey="_id"
        size="small"
        scroll={{ y: height - 38.91, x: props.settings.columns.reduce((previous, current) => previous + current.width, 0) }}
        pagination={props.settings.pagination.enabled ? { pageSize: props.settings.pagination.pageSize } : false}
        expandable={{ expandedRowRender, showExpandColumn: innerColumns.length !== 0 }}
      />
    </div>
  );
}