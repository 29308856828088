import { DataType } from "./data-type";

export enum AggregateFunction {
  Sum = "SUM",
  Average = "AVERAGE",
  First = "FIRST",
  Last = "LAST",
  Max = "MAX",
  Min = "MIN",
  Join = "JOIN",
}

export const aggregateFunctionList = [
  { value: AggregateFunction.Sum, label: "Sum", dataTypeAvailable: [DataType.Number] },
  { value: AggregateFunction.Average, label: "Average", dataTypeAvailable: [DataType.Number] },
  { value: AggregateFunction.First, label: "First", dataTypeAvailable: [DataType.Number, DataType.String, DataType.Date] },
  { value: AggregateFunction.Last, label: "Last",  dataTypeAvailable: [DataType.Number, DataType.String, DataType.Date] },
  { value: AggregateFunction.Max, label: "Max" , dataTypeAvailable: [DataType.Number]},
  { value: AggregateFunction.Min, label: "Min", dataTypeAvailable: [DataType.Number] },
  { value: AggregateFunction.Join, label: "Join", dataTypeAvailable: [DataType.Number, DataType.String, DataType.Date] },
];
