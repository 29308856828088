import { Organization } from "./organization";
import { Role } from "./role";

export class AdminUser {
  id: string = "";
  email: string = "";
  name: string = "";
  enabled: boolean = false;
  mfaEnabled: boolean = false;
  organization: Organization = new Organization();
  role: Role = new Role();
}

export class User {
  id: string = "";
  email: string = "";
  name: string = "";
  enabled: boolean = false;
  mfaEnabled: boolean = false;
  role: Role = new Role();
}
