import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { User } from "@/models/user";
import {
  adminUserService,
  authService,
} from "@/services";
import { gravatar, renderBoolean } from "@/utils";
import { useObservable } from "@/utils/use-observable";
import "./index.less";
import { PasswordInput } from "@/utils/password-input-strenght";
import { ColumnsType } from "antd/lib/table";

const { Search } = Input;

export default function AdminUsersPage() {
  const columns: ColumnsType<User> = [
    {
      title: "Name",
      key: "name",
      width: "300px",
      sorter: {
        compare: (a: User, b: User) => (a.name > b.name ? -1 : 1),
        multiple: 3,
      },
      render: (_: string, row: User) => (
        <List.Item.Meta
          avatar={<Avatar src={gravatar(row.email, 200)} />}
          title={<Link to={row.id}>{row.name}</Link>}
          description={row.email}
        />
      ),
    },
    {
      title: "MFA",
      key: "mfaEnabled",
      width: "50px",
      align: "center",
      render: (_: string, row: User) => renderBoolean(row.mfaEnabled),
    },
    {
      title: "Actions",
      key: "actions",
      width: "100px",
      render: (_: string, user: User) =>
        claims?.sub === user.id ? (
          <></>
        ) : (
          <Button
            type={user.enabled ? "default" : "primary"}
            block
            loading={disableLoading}
            onClick={onChangeStatus(user)}
          >
            {user.enabled ? "Disable" : "Enable"}
          </Button>
        ),
    },
  ];

  const isFetching = useObservable(adminUserService.isFetching);
  const users = useObservable(adminUserService.users);
  const [searchText, setSearchText] = useState("");
  const [disableLoading, setDisableLoading] = useState(false);
  const claims = useObservable(authService.claims);

  useEffect(() => {
    adminUserService.getUsers();
  }, []);

  const onRefresh = () => {
    adminUserService.refresh();
  };

  const onSearch = (value: string) => {
    setSearchText(value.toLowerCase());
  };

  const onChangeStatus = (user: User) => () => {
    setDisableLoading(true);
    (user.enabled
      ? adminUserService.disableUser(user.id)
      : adminUserService.enableUser(user.id)
    ).then((response) => {
      setDisableLoading(false);
      if (response) {
        message.success(`User ${user.enabled ? "enabled" : "disabled"}`, 4);
      }
    });
  };

  return (
    <div className="users-page">
      <Row className="actions" justify="start" gutter={[8, 8]} align="bottom">
        <Col className="action" sm={8} xs={24}>
          <AddUserButton />
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Button
            style={{ width: "100%" }}
            icon={<SyncOutlined spin={isFetching} />}
            onClick={onRefresh}
          >
            Refresh
          </Button>
        </Col>
        <Col className="action" sm={8} xs={24}>
          <Search
            style={{ width: "100%" }}
            placeholder="Search user"
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <Table
        style={{ maxWidth: "800px" }}
        columns={columns}
        dataSource={users.filter((user) =>
          user.name.toLowerCase().includes(searchText)
        )}
        rowKey="id"
        size="small"
        pagination={{ position: ["bottomCenter"] }}
        scroll={{ x: 800 }}
      />
    </div>
  );
}

function AddUserButton() {
  const [newUserVisible, setNewUserVisible] = useState(false);

  const showNewUserModal = () => {
    setNewUserVisible(true);
  };

  const hideNewUserModal = (added: boolean) => {
    setNewUserVisible(false);
    if (added) {
      adminUserService.getUsers();
    }
  };

  return (
    <>
      <Button
        style={{ width: "100%" }}
        type="primary"
        icon={<PlusOutlined />}
        onClick={showNewUserModal}
      >
        New User
      </Button>
      {newUserVisible && (
        <UserNewForm visible={true} onFinish={hideNewUserModal} />
      )}
    </>
  );
}

function UserNewForm(props: {
  visible: boolean;
  onFinish: (added: boolean) => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const isAdding = useObservable(adminUserService.isAdding);

  const onSubmit = ({
    name,
    password,
    email,
  }: {
    name: string;
    password: string;
    email: string;
  }) => {
    adminUserService
      .addUser(name, password, email)
      .then((response) => {
        if (response) {
          message.success("User added", 4);
          props.onFinish(true);
          form.resetFields();
        }
      });
  };

  const onCancel = () => {
    props.onFinish(false);
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={onCancel}
      title="New User"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isAdding}
          onClick={form.submit}
        >
          {t("modal.new.submit")}
        </Button>,
      ]}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          validateTrigger="onBlur"
          validateFirst={true}
          rules={[{
            validator: (_, value) => {
              return !!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g) ? Promise.resolve() : Promise.reject(new Error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"));
            }
          }]}
        >
          <PasswordInput />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            { required: true, message: t("error.fieldRequired") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("changePassword.error.passwordNotMatch")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}
