import { AutoComplete, AutoCompleteProps } from "antd";
import React, { KeyboardEvent } from "react";

export interface AutoCompleteInputProps extends AutoCompleteProps {
  options: Option[];
}

export interface Option {
  trigger: string;
  closer?: string;
  addTrigger?: boolean;
  items: string[];
}

export interface AutoCompleteState {
  value?: string;
  option?: Option;
  startFilterIndex?: number;
  endFilterIndex?: number;
  filter?: string;
  filterCompleteMatch: boolean;
  inputRef?: any
}

export class AutocompleteInput extends React.Component<AutoCompleteInputProps, AutoCompleteState> {
  constructor(props: AutoCompleteInputProps) {
    super(props);
    this.state = {
      value: props.defaultValue?.toString() ?? props.value?.toString(),
      option: undefined,
      startFilterIndex: undefined,
      endFilterIndex: undefined,
      filter: undefined,
      filterCompleteMatch: false,
      inputRef: undefined,
    };
  }
  interruptChars = [" ", ",", "!", ":", "?", "-", "+", "/", "*", "@", "(", ")"];

  componentDidUpdate(_: AutoCompleteProps, prevState: AutoCompleteState) {
    if (prevState.value !== this.state.value) {
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }
    }
  }

  computeOptions = () => {
    const index = this.state.inputRef.selectionStart - 1;
    const chars = [...(this.state.inputRef.value ?? "")]
    let filter = "";
    let option = undefined;
    let startFilterIndex = undefined;
    let endFilterIndex = index;
    let filterCompleteMatch = false;

    for (let i = index; i >= 0; i--) {
      const searchOption = this.props.options.find((option) => option.trigger === chars[i]);
      if (searchOption) {
        option = searchOption;
        startFilterIndex = i;
        break;
      }
      if (this.interruptChars.includes(chars[i]) || this.props.options.some((option) => option.closer === chars[i])) {
        this.setState({ option: undefined, filter: undefined, startFilterIndex: undefined, endFilterIndex: undefined, filterCompleteMatch: false });
        return;
      }
      filter += chars[i];
    }

    if (!option) {
      this.setState({ option: undefined, filter: undefined, startFilterIndex: undefined, endFilterIndex: undefined, filterCompleteMatch: false });
      return;
    }

    filter = filter.split("").reverse().join("");

    for (let i = index + 1; i < chars.length; i++) {
      if (this.props.options.some((option) => option.closer === chars[i])) {
        filterCompleteMatch = true;
        endFilterIndex = i;
        break;
      }
      if (this.interruptChars.includes(chars[i]) || this.props.options.some((option) => option.closer === chars[i])) {
        break;
      }
      endFilterIndex = i;
      filter += chars[i];
    }
    this.setState({ option, filter, startFilterIndex, endFilterIndex, filterCompleteMatch });
  }

  onKeyUp = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      this.computeOptions();
    }
  }

  onClick = () => {
    this.computeOptions();
  }

  onChange = (value: string) => {
    this.computeOptions();
    this.setState({ value });
  }

  getDropDownItems = () => {
    const items = this.state.option?.items?.map((item, index) => { return { key: item, label: item, value: item } }) ?? [];
    if (this.state.filter) {
      return items.filter((item) => this.state.filterCompleteMatch ? item.key.toLowerCase() === this.state.filter!.toLowerCase() : item.key.toLowerCase().startsWith(this.state.filter!.toLowerCase()));
    }
    return items;
  }

  camelCaseToTitleCase = (camel: string) => {
    const result = camel.replace(/\.([a-z])/g, function (_, c) { return c.toUpperCase(); }).replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  getItemSelected = (selection: any) => {
    const value = this.state.inputRef.value;
    const newValue = value.slice(0, this.state.startFilterIndex) +
      (this.state.option!.addTrigger ? this.state.option!.trigger : "") +
      selection +
      (this.state.option!.addTrigger && this.state.option!.closer ? this.state.option!.closer : "") +
      value.slice(this.state.endFilterIndex! + 1);

    this.setState({ value: newValue, option: undefined, filter: undefined, startFilterIndex: undefined, endFilterIndex: undefined, filterCompleteMatch: false });
  }

  onFocus = (event: any) => {
    this.setState({ inputRef: event.target });
  }

  onBlur = () => {
    this.setState({ inputRef: undefined, option: undefined, startFilterIndex: undefined, endFilterIndex: undefined, filter: undefined, filterCompleteMatch: false });
  }


  render() {
    return <AutoComplete
      {...this.props}
      value={this.state.value}
      open={!!this.state.option}
      options={this.getDropDownItems()}
      onChange={this.onChange}
      onSelect={this.getItemSelected}
      onKeyUp={this.onKeyUp}
      onFocus={this.onFocus}
      onBlur={this.onBlur}
      onClick={this.onClick}
      filterOption={false}
    />
  }
}