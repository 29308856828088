import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import { useEffect, useState } from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  SettingOutlined,
} from "@ant-design/icons";
import { Filter } from "@/models/dashboard";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FilterType } from "@/models/enum/filter-type";
import { AddFilterButton, DeleteFilterIcon, FilterForm } from "./filter-form";
import { AddWidgetButton } from "../widget/widget-form";

const { Text } = Typography;

export function Filters() {
  const isEditing = useObservable(dashboardService.isEditing);
  const filters = useObservable(dashboardService.filters);

  return (
    filters && (
      <Row gutter={[16, 16]}>
        {filters.map((filter) => (
          <Col xl={3} md={8} sm={12} xs={24} key={filter.field}>
            <DashboardFilter
              filter={filter}
            />
          </Col>
        ))}
        {isEditing && <>
          <Col xl={3} md={8} sm={12} xs={24}>
            <AddFilterButton />
          </Col>
          <Col xl={3} md={8} sm={12} xs={24}>
            <AddWidgetButton />
          </Col>
        </>
        }
      </Row>
    )
  );
}

export function DashboardFilter(props: {
  filter: Filter;
  isTest?: boolean;
}) {
  const isEditing = useObservable(dashboardService.isEditing);
  const [editing, setEditing] = useState(false);
  const [filterFormVisible, setFilterFormVisible] = useState(false);
  const [value, setValue] = useState<string | undefined>();

  useEffect(() => {
    if (!props.isTest) {
      setEditing(isEditing);
    }
  }, [props.isTest, isEditing]);

  useEffect(() => {
    setValue(props.filter.filterValue.value);
  }, [props.filter]);

  const showFilterFormModal = () => {
    setFilterFormVisible(true);
  };

  const hideFilterFormModal = () => {
    setFilterFormVisible(false);
  };

  const changeFilter = (value: string) => {
    if (!editing && !props.isTest) {
      dashboardService.setFilter(props.filter.name, value);
    }
    if (props.isTest) {
      setValue(value);
    }
  };

  return (
    <>
      {props.filter.type === FilterType.SELECT &&
        <Select
          options={props.filter.filterValue.options}
          value={value}
          onChange={changeFilter}
          placeholder={props.filter.name}
          allowClear={!editing}
          open={editing ? false : undefined}
          suffixIcon={editing ?
            <>
              <Button
                icon={<SettingOutlined />}
                size="small"
                shape="circle"
                type="text"
                onClick={showFilterFormModal}
              />
              <DeleteFilterIcon filter={props.filter} />
            </> : undefined}
          style={{ width: "100%" }}
          tagRender={editing ? (props: CustomTagProps) =>
            <Tag style={{ marginRight: 3 }}>
              <Text>{props.label}</Text>
            </Tag>
            : undefined}
          mode={
            props.filter.multiValue
              ? props.filter.filterValue.options
                ? "multiple"
                : "tags"
              : undefined
          }
        />
      }
      {props.filter.type === FilterType.INPUT &&
        <Input
          value={value}
          onChange={(e) => changeFilter(e.target.value)}
          placeholder={props.filter.name}
          suffix={editing ?
            <>
              <Button
                icon={<SettingOutlined />}
                size="small"
                shape="circle"
                type="text"
                onClick={showFilterFormModal}
              />
              <DeleteFilterIcon filter={props.filter} />
            </> : undefined} />
      }
      {filterFormVisible && !props.isTest &&
        <FilterForm
          filter={props.filter}
          onFinish={hideFilterFormModal}
        />
      }
    </>
  );
}
