import { message } from "antd";
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AccessTokenResponse, LoginResponse, RestoreTokenResponse } from "@/models/auth";
import { TokenClaims } from "@/models/token-claims";
import { RestError } from "@/models/error";
import { t } from "i18next";
import { Buffer } from "buffer";
import { Permission } from "@/models/enum/permission";

declare global {
  interface Window {
    env: any;
  }
}

export class AuthService {
  private token?: string;
  private isRestored = new ReplaySubject<boolean>(1);
  private isRefreshing = false;
  private refreshTokenSubject = new Subject<string | null>();
  private ignoreMessages = ["Revoked token", "Refresh token expired or not provided"];

  claims = new BehaviorSubject<TokenClaims | null>(null);
  isLogged = new BehaviorSubject<boolean | null>(null);
  isAdmin = this.claims.pipe(map((claims) => claims?.admin ?? false));
  isMFAOtpRequired = new BehaviorSubject<boolean>(false);
  isSelectOrganization = this.claims.pipe(map((claims) => !claims?.organizationId));

  constructor() {
    this.restoreToken();
  }

  hasPermission = (permission: Permission): Observable<boolean> => {
    return this.claims.pipe(
      map((claims) => {
        if (!claims) {
          return false;
        }
        if (claims.admin === true) {
          return true;
        }
        if (claims.permissions?.includes(permission) === true) {
          return true;
        }
        return false;
      }
      )
    );
  };

  private getToken = (): string | undefined => {
    return this.token;
  };

  private setToken = (accessToken: string) => {
    this.token = accessToken;
    if (!this.isLogged.value) {
      this.isLogged.next(true);
    }
    this.claims.next(this.getClaims(accessToken));
  };

  private removeToken = () => {
    this.token = undefined;

    if (this.isLogged.value === null || this.isLogged.value) {
      this.isLogged.next(false);
    }
    this.claims.next(null);
  };

  private setHeader = (
    token?: string,
    init?: RequestInit
  ): RequestInit => {
    const authInit = init || {};

    if (token) {
      authInit.headers = {
        ...authInit.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return authInit;
  };

  private getClaims = (token: string): TokenClaims | null => {
    const split = token.split(".");

    if (split.length < 2) {
      return null;
    }

    try {
      return JSON.parse(
        Buffer.from(token.split(".")[1], "base64").toString()
      ) as TokenClaims;
    } catch (e) {
      return null;
    }
  };

  private restoreToken = async () => {
    try {
      const response = await this.fetch("/auth/restore-token", {
        credentials: 'include',
        method: "POST",
      });

      if (!response?.ok || !response?.body) {
        this.isLogged.next(false);
        return;
      }

      const restoreResponse = Object.assign(
        new RestoreTokenResponse(),
        await response.json() as RestoreTokenResponse
      );

      this.isLogged.next(restoreResponse.logged);
      if (restoreResponse.logged) {
        this.setToken(restoreResponse.accessToken!);
      }
    } finally {
      this.isRestored.next(true);
    }
  };

  private refreshToken = async (): Promise<string | null> => {
    this.isRefreshing = true;

    const response = await this.fetch("/auth/refresh-token", {
      credentials: 'include',
      method: "POST",
    });

    if (!response?.ok || !response?.body) {
      this.removeToken();
      this.isRefreshing = false;
      this.refreshTokenSubject.next(null);
      return null;
    }

    const newTokens = Object.assign(
      new AccessTokenResponse(),
      await response.json()
    );

    this.setToken(newTokens.accessToken);

    this.isRefreshing = false;
    this.refreshTokenSubject.next(newTokens.accessToken);

    return newTokens.accessToken;
  };

  login = async (
    email: string,
    password: string,
    remember: boolean,
    mfaOtp?: string,
  ): Promise<boolean | null> => {
    const response = await this.fetch("/auth/authenticate", {
      credentials: 'include',
      method: "POST",
      body: JSON.stringify({ email, password, remember, mfaOtp }),
    });

    if (!response || !response?.ok) {
      return false;
    }

    const newToken = Object.assign(
      new LoginResponse(),
      await response.json()
    );

    if (newToken.mfaOtpRequired) {
      this.isMFAOtpRequired.next(true);
      return true;
    }

    this.setToken(newToken.accessToken);

    return true;
  };

  logout = async () => {
    this.authFetch("/auth/revoke-token", { method: "POST" }).then(() => {
      this.removeToken();
    });
  };

  selectOrganization = async (
    organizationId: string
  ): Promise<boolean | null> => {
    const response = await this.authFetch("/auth/select-organization", {
      method: "POST",
      body: JSON.stringify({ organizationId }),
    });

    if (!response?.ok) {
      return false;
    }

    const newTokens = Object.assign(
      new AccessTokenResponse(),
      await response.json()
    );

    this.setToken(newTokens.accessToken);

    return true;
  };

  changePassword = async (
    password: string,
    newPassword: string
  ): Promise<boolean | null> => {
    const response = await this.authFetch("/auth/change-password", {
      method: "POST",
      body: JSON.stringify({ password, newPassword }),
    });

    return !!response?.ok;
  };

  resetPassword = async (email: string): Promise<boolean | null> => {
    const response = await this.fetch("/auth/reset-password", {
      method: "POST",
      body: JSON.stringify({ email }),
    });

    if (!response) {
      return null;
    }

    return response.ok;
  };

  newPassword = async (
    token: string,
    password: string
  ): Promise<boolean | null> => {
    const response = await this.fetch("/auth/new-password", {
      method: "POST",
      body: JSON.stringify({ token, password }),
    });

    if (!response) {
      return null;
    }

    return response.ok;
  };

  enableAccount = async (token: string): Promise<boolean | null> => {
    const response = await this.fetch("/auth/enable-account", {
      method: "POST",
      body: JSON.stringify({ token }),
    });

    if (!response) {
      return null;
    }

    return response.ok;
  };

  fetch = async (
    input: string,
    init?: RequestInit
  ): Promise<Response | null> => {
    const params = {
      ...init,
      headers: {
        ...init?.headers,
        "Content-Type": "application/json",
      },
    } as RequestInit;

    return fetch(window.env.baseApiUrl + input, params).then(
      async (response) => {
        if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 401
        ) {
          const error = Object.assign(new RestError(), await response.json());
          if (error.message) {
            if (!this.ignoreMessages.includes(error.message)) {
              message.error(error.message, 4);
            }
          } else {
            message.error(t("error.client"), 4);
          }
        }

        if (response.status >= 500) {
          message.error(t("error.server"), 4);
        }

        return response;
      },
      () => {
        message.error(t("error.server"), 4);
        return null;
      }
    );
  };

  authFetch = async (
    input: string,
    init?: RequestInit
  ): Promise<Response | null> => {
    await firstValueFrom(this.isRestored);

    const token = this.getToken();

    return this.fetch(input, this.setHeader(token, { ...init, credentials: 'include' })).then(
      async (response) => {
        if (response?.ok === false) {
          if (response.status === 401) {
            const newToken = await (this.isRefreshing
              ? firstValueFrom(this.refreshTokenSubject)
              : this.refreshToken());
            if (newToken) {
              return this.authFetch(input, init);
            } else {
              this.removeToken();
            }
          }
        }

        return response;
      }
    );
  };
}
