import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import "./index.less";
import { useObservable } from "@/utils/use-observable";
import { authService, dashboardService } from "@/services";
import { useEffect, useRef, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useWidth } from "@/utils/use-dimension";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  StarFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { useNavigate, useParams } from "react-router-dom";
import { Dashboard, Widget } from "@/models/dashboard";
import { Permission } from "@/models/enum/permission";
import { WidgetCard } from "./components/widget/widget";
import { Filters } from "./components/filter/filter";
import { WidgetForm } from "./components/widget/widget-form";
import useMediaQuery from "@/utils/use-media-query";

const { Title } = Typography;

export default function DashboardPage() {
  const dashboardId = useParams().id!!;
  const dashboard = useObservable(dashboardService.dashboard);

  useEffect(() => {
    dashboardService.getDashboard(dashboardId);

    return () => {
      dashboardService.disposeDashboard();
    };
  }, [dashboardId]);

  if (!dashboard) {
    return <Loader />;
  }

  return (
    <div className="dashboard-page">
      <DashboardTitle />
      <Divider />
      <Layout />
    </div>
  );
}

function DashboardTitle() {
  const dashboard = useObservable(dashboardService.dashboard)!;
  const isEditing = useObservable(dashboardService.isEditing);
  const manageDashboard = useObservable(authService.hasPermission(Permission.ManageDashboards));
  const router = useNavigate();
  const canEdit = useMediaQuery("(min-width: 768px)")

  useEffect(() => {
    if (!canEdit && isEditing) {
      onCancel();
    }
  }, [canEdit, isEditing]);

  const onEdit = () => {
    dashboardService.editing();
  };

  const onCancel = () => {
    dashboardService.cancelEditing();
  };

  const onSave = () => {
    dashboardService.update();
  };

  const onDelete = () => {
    dashboardService.delete().then((result) => {
      if (result) {
        router("/");
      }
    });
  };

  const setFavorite = () => {
    dashboardService.setFavorite(
      dashboard.id,
      !(dashboard.settings?.favorite ?? false),
      true
    );
  };

  return (
    <Row justify={"space-between"} gutter={[16, 16]}>
      <Col>
        <Space>
          <Button
            icon={
              <StarFilled
                style={{
                  color: dashboard.settings?.favorite ? "gold" : "grey",
                  fontSize: "25px",
                }}
              />
            }
            onClick={setFavorite}
            shape="circle"
            type="text"
          />
          <Title level={3} style={{ margin: 0 }} editable={isEditing ? {
            onChange: (name: string) => {
              const dd = dashboardService.dashboard.value!!;
              dd.name = name;
              dashboardService.dashboard.next(Object.assign(new Dashboard(), dd));
            }
          } : undefined}>
            {dashboard.name}
          </Title>
        </Space>
      </Col>
      <Col>
        {canEdit && manageDashboard && !isEditing && (
          <Tooltip title="Edit">
            <Button icon={<EditOutlined />} onClick={onEdit} shape="circle" />
          </Tooltip>
        )}
        {isEditing && (
          <Space>
            <Tooltip title="Save">
              <Button type="primary" icon={<SaveOutlined />} onClick={onSave} shape="circle" />
            </Tooltip>
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} onClick={onDelete} shape="circle" />
            </Tooltip>
            <Tooltip title="Cancel">
              <Button icon={<CloseOutlined />} onClick={onCancel} shape="circle" />
            </Tooltip>
          </Space>
        )}
      </Col>
    </Row>
  );
}

function Layout() {
  const divRef = useRef(null);
  const width = useWidth(divRef);
  const isEditing = useObservable(dashboardService.isEditing);
  const widgets = useObservable(dashboardService.widgets);
  const [widgetToEdit, setWidgetToEdit] = useState<Widget | undefined>(undefined);

  const onEditWidget = (widget: Widget) => {
    setWidgetToEdit(widget);
  };

  const hideWidgetFormModal = () => {
    setWidgetToEdit(undefined);
  };

  const onDeleteWidget = (widget: Widget) => {
    dashboardService.deleteWidget(widget.id);
  };

  const onLayoutChange = (newLayout: any) => {
    dashboardService.setLayout(newLayout);
  };

  return (
    <>
      <Filters />
      {widgetToEdit && <WidgetForm widget={widgetToEdit} onFinish={hideWidgetFormModal} />}
      <div ref={divRef} style={!widgets ? { height: 200 } : {}}>
        {widgets && width > 0 && (
          <ResponsiveGridLayout
            cols={{ lg: 24, md: 18, sm: 12, xs: 8, xxs: 4 }}
            rowHeight={10}
            isDraggable={isEditing}
            isResizable={isEditing}
            width={width}
            onLayoutChange={isEditing ? onLayoutChange : undefined}
            draggableCancel={".settings,.ant-dropdown"}
          >
            {widgets.map((widget, index) => {
              return (
                <div
                  className="widget"
                  key={index}
                  data-grid={widget.position}
                >
                  <WidgetCard widget={widget} onEdit={onEditWidget} onDelete={onDeleteWidget} />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        )}
      </div>
    </>
  );
}
