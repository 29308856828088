import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  theme,
  Typography,
} from "antd";
import { useObservable } from "@/utils/use-observable";
import { dashboardService } from "@/services";
import { useState } from "react";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import {
  PlusOutlined,
  LeftOutlined,
  CloseOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import Loader from "@/app/components/loader";
import { useTranslation } from "react-i18next";
import { getWidgetTypeLabel, WidgetType, widgetTypeList } from "@/models/enum/widget-types";
import pieChart from "./icons/pie-chart.png";
import tableChart from "./icons/table-chart.png";
import barChart from "./icons/bar-chart.png";
import lineChart from "./icons/line-chart.png";
import cardChart from "./icons/card-chart.png";
import { AutocompleteInput } from "@/utils/autocomplete-input";
import { DataType, dataTypeList } from "@/models/enum/data-type";
import { aggregateFunctionList } from "@/models/enum/aggregate-function";
import { uuidv4 } from "@/utils";
import { Widget } from "@/models/dashboard";
import { WidgetCard } from "./widget";
import { BarSettings } from "@/models/widget-settings/bar-settings";
import { CardSettings } from "@/models/widget-settings/card-settings";
import { LineSettings } from "@/models/widget-settings/line-settings";
import { PieSettings } from "@/models/widget-settings/pie-settings";
import { TableSettings } from "@/models/widget-settings/table-settings";
import { alignList } from "@/models/enum/align";

const { Title } = Typography;

export function AddWidgetButton() {
  const [widgetFormVisible, setWidgetFormVisible] = useState(false);

  const showWidgetFormModal = () => {
    setWidgetFormVisible(true);
  };

  const hideWidgetFormModal = () => {
    setWidgetFormVisible(false);
  };

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={showWidgetFormModal}
        block
      >
        Add Widget
      </Button>
      {widgetFormVisible &&
        <WidgetForm
          onFinish={hideWidgetFormModal}
        />
      }
    </>
  );
}

export function WidgetForm(props: {
  widget?: Widget;
  onFinish: () => void;
}) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [type, setType] = useState(props.widget?.type);
  const [preview, setPreview] = useState<Widget | undefined>();
  const isTesting = useObservable(dashboardService.isTestingWidget);
  const isSaving = useObservable(dashboardService.isSavingWidget);

  const onChangeType = (value: WidgetType) => {
    setType(value);
  }

  const onBack = () => {
    setType(undefined);
  }

  const onSubmit = (values: any) => {
    const widget = Object.assign(new Widget(), {
      position: {
        x: 0,
        y: 0,
        w: 3,
        h: 7,
      }
    }, props.widget, {
      type: type,
      name: values.name,
      settings: JSON.stringify(values.settings),
    });
    if (!props.widget) {
      widget.id = uuidv4();
      dashboardService.addWidget(widget).then((result) => {
        if (result) {
          props.onFinish();
          form.resetFields();
        }
      });
    } else {
      dashboardService.updateWidget(widget).then((result) => {
        if (result) {
          props.onFinish();
          form.resetFields();
        }
      });
    }
  };

  const onCancel = () => {
    props.onFinish();
  };

  const onTest = () => {
    form.validateFields().then((values) => {
      const widget = Object.assign(new Widget(), {
        type: type,
        name: values.name,
        settings: JSON.stringify(values.settings),
      });
      dashboardService.testWidget(widget).then((value) => {
        setPreview(value);
      });
    }, () => { });
  };

  const getWidgetTypeForm = () => {
    switch (type) {
      case WidgetType.Card:
        return <CardForm settings={props.widget?.parsedSettings} form={form} />;
      case WidgetType.Bar:
      case WidgetType.Line:
        return <BarLineForm settings={props.widget?.parsedSettings} form={form} />;
      case WidgetType.Pie:
        return <PieForm settings={props.widget?.parsedSettings} form={form} />;
      case WidgetType.Table:
        return <TableForm settings={props.widget?.parsedSettings} form={form} />;
    }
  }

  return (
    <Modal
      open
      onCancel={props.onFinish}
      title={props.widget ? "Edit Widget" : "New Widget"}
      footer={type ? [
        <Button key="test" onClick={onTest}>
          Test
        </Button>,
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={isSaving}
        >
          {t("modal.new.submit")}
        </Button>
      ] : []}
    >
      {!type ?
        <SelectWidgetType onChange={onChangeType} />
        :
        <>
          <Space style={{ marginBottom: 16 }}>
            <Button type="text" shape="circle" size="small" icon={<LeftOutlined />} onClick={onBack} />
            <Title level={5} style={{ margin: 0 }}>{getWidgetTypeLabel(type)}</Title>
          </Space>
          <Form labelCol={{ span: 8 }} form={form} onFinish={onSubmit}>
            <Form.Item
              initialValue={props.widget?.name}
              labelAlign="left"
              label="Name"
              name="name"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
            >
              <Input />
            </Form.Item>
            {getWidgetTypeForm()}
            <Form.Item>
              {(isTesting || preview) &&
                <Card title="Preview" style={{ height: "300px" }} styles={{ body: { height: "300px" } }}>
                  {isTesting ?
                    <Loader /> :
                    <WidgetCard
                      widget={preview!!}
                      isTest
                    />
                  }
                </Card>
              }
            </Form.Item>
          </Form>
        </>
      }
    </Modal >
  );
}

function SelectWidgetType(props: {
  onChange: (value: WidgetType) => void
}) {

  const getLogo = (type: WidgetType) => {
    switch (type) {
      case WidgetType.Card:
        return cardChart
      case WidgetType.Line:
        return lineChart;
      case WidgetType.Pie:
        return pieChart;
      case WidgetType.Bar:
        return barChart;
      case WidgetType.Table:
        return tableChart;
    }
  }

  return (
    <Row gutter={[16, 16]}>
      {widgetTypeList.map((type) =>
        <Col key={type.value} sm={12} xs={24}>
          <Card onClick={() => { props.onChange(type.value) }} style={{ cursor: "pointer" }} >
            <center>
              <Title level={4}>{type.label}</Title>
              <img src={getLogo(type.value)} width={100} height={100} alt="Logo" />
            </center>
          </Card>
        </Col>
      )}
    </Row>
  );
}

function CardForm(props: { settings?: CardSettings, form: FormInstance<any> }) {
  const { t } = useTranslation();
  const dashboard = useObservable(dashboardService.dashboard);
  const [dataType, setDataType] = useState(props.settings?.dataType);

  const onChangeType = (value: DataType) => {
    setDataType(value);
    props.form.setFieldsValue({ settings: { "aggregateFunction": undefined, mask: undefined } });
  };

  return (
    <>
      <Form.Item
        initialValue={props.settings?.field}
        labelAlign="left"
        label="Field"
        name={["settings", "field"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
        extra={<>
          <Typography.Paragraph style={{ margin: 0 }}>Can be a string or an expression.</Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>Insert "@" for functions list and "&#123;" for fields list.</Typography.Paragraph>
        </>}
      >
        <AutocompleteInput options={[{ trigger: "{", items: dashboard?.dataset.fields!!, addTrigger: true, closer: "}" }, { trigger: "@", items: ["min(param1,param2)", "max(param1,param2)", "percentage(param1,param2)"] }]} />
      </Form.Item>
      <Form.Item
        initialValue={props.settings?.dataType}
        labelAlign="left"
        label="Data Type"
        name={["settings", "dataType"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={dataTypeList} onChange={onChangeType} />
      </Form.Item>
      <Form.Item
        initialValue={props.settings?.aggregateFunction}
        labelAlign="left"
        label="Aggregate Function"
        name={["settings", "aggregateFunction"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={aggregateFunctionList.filter((item) => dataType ? item.dataTypeAvailable.includes(dataType) : true)} />
      </Form.Item>
      {(dataType === DataType.Number || dataType === DataType.Date) &&
        < Form.Item
          initialValue={props.settings?.mask}
          labelAlign="left"
          label="Mask"
          name={["settings", "mask"]}
          extra={<>
            {dataType === DataType.Number && <Typography.Text>Number format example: "€ #.##0,00", "#0,00 %"</Typography.Text>}
            {dataType === DataType.Date && <Typography.Text>Date mask example: "YYYY/MM/DD", "DD/MM/YYYY"</Typography.Text>}
          </>}
        >
          <Input />
        </Form.Item >
      }
    </>
  );
}

function BarLineForm(props: { settings?: BarSettings | LineSettings, form: FormInstance<any> }) {
  const { t } = useTranslation();
  const dashboard = useObservable(dashboardService.dashboard);

  const getFieldsOptions = () => {
    return dashboard?.dataset.fields.map((field) => {
      return { label: field, value: "{" + field + "}" };
    });
  }

  return (
    <>
      <Form.Item
        initialValue={props.settings?.aggregateFunction}
        labelAlign="left"
        label="Aggregate Function"
        name={["settings", "aggregateFunction"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={aggregateFunctionList.filter((item) => item.dataTypeAvailable.includes(DataType.Number))} />
      </Form.Item>
      <Divider />
      <Typography.Paragraph>X Asix</Typography.Paragraph>
      <Form.Item
        initialValue={props.settings?.x.name}
        labelAlign="left"
        label="Name"
        name={["settings", "x", "name"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        initialValue={props.settings?.x.field}
        labelAlign="left"
        label="Field"
        name={["settings", "x", "field"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={getFieldsOptions()} />
      </Form.Item>
      <Divider />
      <Typography.Paragraph>Y Asix</Typography.Paragraph>
      <Form.List
        initialValue={props.settings?.y}
        name={["settings", "y"]}>
        {(fields, { add, remove }, { errors }) => (
          <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
            {fields.map((field, index) => (
              <Card
                key={field.key}
                size="small"
                title={`Item ${field.name + 1}`}
                extra={
                  <CloseOutlined
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              >
                <Form.Item
                  labelAlign="left"
                  label="Name"
                  name={[field.name, 'name']}
                  rules={[{ required: true, message: t("error.fieldRequired") }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  label="Field"
                  name={[field.name, 'field']}
                  rules={[{ required: true, message: t("error.fieldRequired") }]}
                  extra={<>
                    <Typography.Paragraph style={{ margin: 0 }}>Can be a string or an expression.</Typography.Paragraph>
                    <Typography.Paragraph style={{ margin: 0 }}>Insert "@" for functions list and "&#123;" for fields list.</Typography.Paragraph>
                  </>}
                >
                  <AutocompleteInput options={[{ trigger: "{", items: dashboard?.dataset.fields!!, addTrigger: true, closer: "}" }, { trigger: "@", items: ["min(param1,param2)", "max(param1,param2)", "percentage(param1,param2)"] }]} />
                </Form.Item>
              </Card>
            ))}
            <Form.Item>
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
                block
              >
                Add Y Asix Item
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
    </>
  );
}

function PieForm(props: { settings?: PieSettings, form: FormInstance<any> }) {
  const { t } = useTranslation();
  const dashboard = useObservable(dashboardService.dashboard);

  const getFieldsOptions = () => {
    return dashboard?.dataset.fields.map((field) => {
      return { label: field, value: "{" + field + "}" };
    });
  }

  return (
    <>
      <Form.Item
        initialValue={props.settings?.aggregateFunction}
        labelAlign="left"
        label="Aggregate Function"
        name={["settings", "aggregateFunction"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={aggregateFunctionList.filter((item) => item.dataTypeAvailable.includes(DataType.Number))} />
      </Form.Item>
      <Divider />
      <Typography.Paragraph>Key</Typography.Paragraph>
      <Form.Item
        initialValue={props.settings?.key.name}
        labelAlign="left"
        label="Name"
        name={["settings", "key", "name"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        initialValue={props.settings?.key.field}
        labelAlign="left"
        label="Field"
        name={["settings", "key", "field"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={getFieldsOptions()} />
      </Form.Item>
      <Divider />
      <Typography.Paragraph>Value</Typography.Paragraph>
      <Form.Item
        initialValue={props.settings?.value.name}
        labelAlign="left"
        label="Name"
        name={["settings", "value", "name"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        initialValue={props.settings?.value.field}
        labelAlign="left"
        label="Field"
        name={["settings", "value", "field"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
        extra={<>
          <Typography.Paragraph style={{ margin: 0 }}>Can be a string or an expression.</Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>Insert "@" for functions list and "&#123;" for fields list.</Typography.Paragraph>
        </>}
      >
        <AutocompleteInput options={[{ trigger: "{", items: dashboard?.dataset.fields!!, addTrigger: true, closer: "}" }, { trigger: "@", items: ["min(param1,param2)", "max(param1,param2)", "percentage(param1,param2)"] }]} />
      </Form.Item>
    </>
  );
}

function TableForm(props: { settings?: TableSettings, form: FormInstance<any> }) {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <>
      <Divider />
      <Typography.Paragraph>Columns</Typography.Paragraph>
      <Form.List
        initialValue={props.settings?.columns}
        name={["settings", "columns"]}>
        {(fields, { add, remove }, { errors }) => (
          <div style={{ display: "flex", rowGap: 16, flexDirection: "column" }}>
            <Collapse
              size="small"
              defaultActiveKey={fields.map((f) => f.key)}
              bordered={false}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              style={{ background: token.colorBgContainer }}
              items={fields.map((field) => (
                {
                  key: field.key,
                  label: "Column " + (field.key + 1),
                  children: <ColumnSubForm settings={props.settings} form={props.form} field={field} />,
                  style: panelStyle,
                  extra: <CloseOutlined
                    onClick={() => remove(field.key)}
                  />
                }
              ))}
            >
            </Collapse>
            <Form.Item>
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
                block
              >
                Add Column
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </div>
        )}
      </Form.List>
      <Divider />
      <Typography.Paragraph>Pagination</Typography.Paragraph>
      <Form.Item
        initialValue={props.settings?.pagination.enabled ?? false}
        labelAlign="left"
        label="Enabled"
        name={["settings", "pagination", "enabled"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        initialValue={props.settings?.pagination.pageSize ?? 10}
        labelAlign="left"
        label="Size"
        name={["settings", "pagination", "pageSize"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <InputNumber />
      </Form.Item>
    </>
  );
}

function ColumnSubForm(props: { settings?: TableSettings, form: FormInstance<any>, field: FormListFieldData }) {
  const { t } = useTranslation();
  const dashboard = useObservable(dashboardService.dashboard);
  const [dataType, setDataType] = useState<DataType | undefined>(props.settings?.columns[props.field.name]?.dataType);

  const onChangeType = (value: DataType) => {
    setDataType(value);

    const fields = props.form.getFieldsValue();
    const columns = fields.settings.columns;
    Object.assign(columns[props.field.name], { aggregateFunction: undefined, mask: undefined });
    props.form.setFieldsValue(fields);
  };

  return (
    <>
      <Form.Item
        labelAlign="left"
        label="Name"
        name={[props.field.name, 'name']}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        labelAlign="left"
        label="Field"
        name={[props.field.name, 'field']}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
        extra={<>
          <Typography.Paragraph style={{ margin: 0 }}>Can be a string or an expression.</Typography.Paragraph>
          <Typography.Paragraph style={{ margin: 0 }}>Insert "@" for functions list and "&#123;" for fields list.</Typography.Paragraph>
        </>}
      >
        <AutocompleteInput options={[{ trigger: "{", items: dashboard?.dataset.fields!!, addTrigger: true, closer: "}" }, { trigger: "@", items: ["min(param1,param2)", "max(param1,param2)", "percentage(param1,param2)"] }]} />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Data Type"
        name={[props.field.name, "dataType"]}
        rules={[{ required: true, message: t("error.fieldRequired") }]}
      >
        <Select options={dataTypeList} onChange={onChangeType} allowClear />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Aggregate Function"
        name={[props.field.name, "aggregateFunction"]}
      >
        <Select options={dataType ? aggregateFunctionList.filter((item) => item.dataTypeAvailable.includes(dataType)) : aggregateFunctionList} />
      </Form.Item>
      {(dataType === DataType.Number || dataType === DataType.Date) &&
        < Form.Item
          labelAlign="left"
          label="Mask"
          name={[props.field.name, "mask"]}
          extra={<>
            {dataType === DataType.Number && <Typography.Text>Number format example: "€ #.##0,00", "#0,00 %"</Typography.Text>}
            {dataType === DataType.Date && <Typography.Text>Date mask example: "YYYY/MM/DD", "DD/MM/YYYY"</Typography.Text>}
          </>}
        >
          <Input />
        </Form.Item >
      }
      <Form.Item
        labelAlign="left"
        label="Width"
        name={[props.field.name, 'width']}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Align"
        name={[props.field.name, 'align']}
      >
        <Select options={alignList} allowClear />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Sortable"
        name={[props.field.name, 'sortable']}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Nested"
        name={[props.field.name, 'nested']}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
    </>
  );
}