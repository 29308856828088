import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminUserService, authService } from "@/services";
import { gravatar } from "@/utils";
import { useObservable } from "@/utils/use-observable";
import Loader from "@app/components/loader";
import { ChangePasswordButton, EnableMFAButton } from "@app/components/user-form";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export default function AdminUserPage() {
  const [form] = Form.useForm();
  const user = useObservable(adminUserService.user);
  const isFetching = useObservable(adminUserService.isFetchingOne);
  const isUpdating = useObservable(adminUserService.isUpdating);
  const isDeleting = useObservable(adminUserService.isDeleting);
  const isTogglingStatus = useObservable(adminUserService.isTogglingStatus);
  const claims = useObservable(authService.claims);
  const router = useNavigate();
  const userId = useParams().id!!;
  const [t] = useTranslation();

  useEffect(() => {
    adminUserService.getUser(userId);

    return () => {
      adminUserService.disposeUser();
    };
  }, [userId]);

  const onSubmit = ({
    name,
    email,
    role,
  }: {
    name: string;
    email: string;
    role: string;
  }) => {
    adminUserService
      .updateUser(user!!.id, name, email, role)
      .then((response) => {
        if (response) {
          message.success("User updated", 4);
        }
      });
  };

  const onChangeStatus = () => {
    if (user) {
      (user.enabled
        ? adminUserService.disableUser(user.id, false)
        : adminUserService.enableUser(user.id, false)
      ).then((response) => {
        if (response) {
          message.success(`User ${user.enabled ? "disabled" : "enabled"}`, 4);
        }
      });
    }
  };

  const onDelete = () => {
    adminUserService.deleteUser(userId).then((response) => {
      if (response) {
        message.success("User updated", 4);
        router("/admin/settings/users");
      }
    });
  };

  const onLogout = () => {
    authService.logout();
  };

  if (!user || isFetching) return <Loader />;

  return (
    <Row justify="center">
      <div style={{ width: "350px" }}>
        <Row align="middle">
          <Avatar size="large" src={gravatar(user?.email, 200)}></Avatar>
          <Title level={3} style={{ margin: "8px 0px 0px 10px" }}>
            {user.name}
          </Title>
        </Row>
        <Divider />
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            initialValue={user.name}
            label="Name"
            name="name"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input disabled={!user.enabled} />
          </Form.Item>
          <Form.Item
            initialValue={user.email}
            label="Email"
            name="email"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input disabled={!user.enabled} />
          </Form.Item>
          {user.enabled && (
            <>
              <Form.Item>
                <Button
                  type="primary"
                  block
                  loading={isUpdating}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
              {claims?.sub !== userId && (
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    block
                    loading={isDeleting}
                    htmlType="button"
                    onClick={onDelete}
                  >
                    Delete
                  </Button>
                </Form.Item>
              )}
              {claims?.sub === userId && (
                <>
                  <Divider />
                  <Form.Item>
                    <EnableMFAButton mfaEnabled={user.mfaEnabled} admin />
                  </Form.Item>
                  <Form.Item>
                    <ChangePasswordButton />
                  </Form.Item>
                  <Divider />
                  <Button danger block onClick={onLogout}>
                    Logout
                  </Button>
                </>
              )}
            </>
          )}
          {claims?.sub !== userId && (
            <>
              <Divider />
              <Button
                block
                type="primary"
                ghost={user.enabled}
                loading={isTogglingStatus}
                onClick={onChangeStatus}
              >
                {user.enabled ? "Disable User" : "Enable User"}
              </Button>
            </>
          )}
        </Form>
      </div>
    </Row>
  );
}
