export enum Align {
  Left = "left",
  Right = "right",
  Center = "center",
}

export const alignList = [
  { value: Align.Left, label: "Left"},
  { value: Align.Right, label: "Right"},
  { value: Align.Center, label: "Center"},
];
