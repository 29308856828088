import { authService } from ".";
import { BehaviorSubject, distinctUntilChanged, map } from "rxjs";
import { Dashboard, Dataset, Filter, Widget } from "@/models/dashboard";
import { DashboardTemplate } from "@/models/dashboard-template";
import { deepClone } from "@/utils";

export class DashboardService {
  regexpFindFields = /\{[^}]*\}/g;

  favorites = new BehaviorSubject<Dashboard[]>([]);
  dashboards = new BehaviorSubject<Dashboard[]>([]);
  isFetching = new BehaviorSubject(false);

  isFetchingOne = new BehaviorSubject(false);
  isAdding = new BehaviorSubject<boolean>(false);
  isUpdating = new BehaviorSubject<boolean>(false);
  isDeleting = new BehaviorSubject<boolean>(false);
  isFetchingValues = new BehaviorSubject(false);
  isTestingFilter = new BehaviorSubject(false);
  isSavingFilter = new BehaviorSubject(false);
  isTestingWidget = new BehaviorSubject(false);
  isSavingWidget = new BehaviorSubject(false);
  dashboard = new BehaviorSubject<Dashboard | null>(null);
  dashboardBackup = new Dashboard();
  filters = new BehaviorSubject<Filter[] | null>(null);
  widgets = new BehaviorSubject<Widget[] | null>(null);
  initialFiltersSubscription = this.dashboard.pipe(
    map((dashboard) => {
      return dashboard?.filters ?? null;
    }),
    distinctUntilChanged()
  ).subscribe(this.filters);
  initialWidgetsSubscription = this.dashboard.pipe(
    map((dashboard) => {
      return dashboard?.widgets ?? null;
    }),
    distinctUntilChanged()
  ).subscribe(this.widgets);
  isEditing = new BehaviorSubject<boolean>(false);

  templates = new BehaviorSubject<DashboardTemplate[]>([]);
  isFetchingTemplates = new BehaviorSubject(false);

  datasets = new BehaviorSubject<Dataset[]>([]);
  datasetOptions = this.datasets.pipe(
    map((datasets) => {
      return datasets.map((dataset) => {
        return {
          label: dataset.name,
          value: dataset.id,
        };
      });
    })
  );
  isFetchingDatasets = new BehaviorSubject(false);

  constructor() {
    this.filters.subscribe(
      (filters) => { if (filters) this.getValues(filters) }
    );
  }

  dispose = async () => {
    this.dashboards.next([]);
    this.templates.next([]);
    this.datasets.next([]);
  };

  disposeDashboard = async () => {
    this.dashboard.next(null);
    if (this.isEditing.value) {
      this.isEditing.next(false);
    }
    this.dashboardBackup = new Dashboard();
  };

  getFavorites = async () => {
    try {
      const response = await authService.authFetch("/dashboards/favorite", {
        method: "GET",
      });
      if (response?.ok) {
        this.favorites.next(
          ((await response.json()) as Dashboard[]).map((x) =>
            Object.assign(new Dashboard(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  getDashboards = async () => {
    try {
      this.isFetching.next(true);

      const response = await authService.authFetch("/dashboards", {
        method: "GET",
      });
      if (response?.ok) {
        this.dashboards.next(
          ((await response.json()) as Dashboard[]).map((x) =>
            Object.assign(new Dashboard(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetching.next(false);
    }
  };

  getDashboard = async (id: string) => {
    try {
      this.isFetchingOne.next(true);

      const response = await authService.authFetch("/dashboards/" + id, {
        method: "GET",
      });
      if (response?.ok) {
        this.dashboard.next(
          Object.assign(new Dashboard(), await response.json())
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingOne.next(false);
    }
  };

  setLayout = async (newLayout: any) => {
    const dashboard = this.dashboard.value!!;
    const widgets = dashboard.widgets;
    widgets.forEach((widget, index) => {
      const newPosition = newLayout[index];
      widget.position = {
        x: newPosition.x,
        y: newPosition.y,
        w: newPosition.w,
        h: newPosition.h,
      }
    });
    dashboard.widgets = [...widgets];
    this.dashboard.next(dashboard);
  }

  editing = async () => {
    this.dashboardBackup = deepClone(this.dashboard.value!!);
    this.isEditing.next(true);
  };

  cancelEditing = async () => {
    if (this.isEditing.value) {
      this.isEditing.next(false);
      this.dashboard.next(this.dashboardBackup);
      this.dashboardBackup = new Dashboard();
    }
  };

  setFavorite = async (id: string, value: boolean, dashboardPage: boolean) => {
    try {
      const response = await authService.authFetch(
        "/dashboards/" + id + "/favorite/" + value,
        {
          method: "POST",
        }
      );
      if (response?.ok) {
        if (dashboardPage) {
          this.getDashboard(id);
        } else {
          this.getDashboards();
        }
        this.getFavorites();
      }
    } catch (e) {
      console.log(e);
    }
  };

  setFilter = async (name: string, value: string) => {
    const dashboard = this.dashboard.value;
    if (!dashboard) {
      return;
    }
    const filters = dashboard.filters;
    if (!filters) {
      return;
    }

    const filterIndex = filters?.findIndex((filter) => filter.name === name);
    if (filterIndex > -1) {
      const filter = filters!![filterIndex];
      filter.filterValue.value = value;
      filters!![filterIndex] = deepClone(filter);
      dashboard.filters = [...filters];
      this.dashboard.next(dashboard);
    }
  };

  deleteFilter = async (id: string) => {
    const dashboard = this.dashboard.value;
    if (!dashboard) {
      return;
    }
    const filters = dashboard.filters;
    if (!filters) {
      return;
    }

    dashboard.filters = filters?.filter((filter) => filter.id !== id);
    this.dashboard.next(dashboard);
  };

  testFilter = async (filter: Filter) => {
    try {
      this.isTestingFilter.next(true);

      const response = await authService.authFetch("/dashboards/" + this.dashboard.value!!.id + "/filter", {
        method: "POST",
        body: JSON.stringify(filter),
      });
      if (response?.ok) {
        return Object.assign(new Filter(), await response.json());
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isTestingFilter.next(false);
    }
  };

  addFilter = async (filter: Filter) => {
    try {
      this.isSavingFilter.next(true);

      const response = await authService.authFetch("/dashboards/" + this.dashboard.value!!.id + "/filter", {
        method: "POST",
        body: JSON.stringify(filter),
      });
      if (response?.ok) {
        const newFilter = Object.assign(new Filter(), await response.json());
        const dashboard = this.dashboard.value;
        if (!dashboard) {
          return false;
        }
        const filters = dashboard.filters;
        if (!filters) {
          return false;
        }

        dashboard.filters = [...filters, newFilter];
        this.dashboard.next(dashboard);
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isSavingFilter.next(false);
    }
  };

  updateFilter = async (filter: Filter) => {
    try {
      this.isSavingFilter.next(true);

      const response = await authService.authFetch("/dashboards/" + this.dashboard.value!!.id + "/filter", {
        method: "POST",
        body: JSON.stringify(filter),
      });
      if (response?.ok) {
        const newFilter = Object.assign(new Filter(), await response.json());
        const dashboard = this.dashboard.value;
        if (!dashboard) {
          return false;
        }
        const filters = dashboard.filters;
        if (!filters) {
          return false;
        }

        const filterIndex = filters?.findIndex((f) => f.id === filter.id);
        if (filterIndex > -1) {
          filters!![filterIndex] = newFilter;
          dashboard.filters = [...filters];
          this.dashboard.next(dashboard);
          return true;
        }
        return false;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isSavingFilter.next(false);
    }
  };

  testWidget = async (widget: Widget) => {
    try {
      this.isTestingWidget.next(true);

      const response = await authService.authFetch("/dashboards/" + this.dashboard.value!!.id + "/widget", {
        method: "POST",
        body: JSON.stringify({ filters: this.filters.value!.filter((filter) => filter.filterValue.value!!).map((filter) => ({ field: filter.field, value: filter.filterValue.value })), widget }),
      });
      if (response?.ok) {
        return Object.assign(new Widget(), await response.json());
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isTestingWidget.next(false);
    }
  };

  addWidget = async (widget: Widget) => {
    try {
      this.isSavingWidget.next(true);

      const response = await authService.authFetch("/dashboards/" + this.dashboard.value!!.id + "/widget", {
        method: "POST",
        body: JSON.stringify({ filters: this.filters.value!.filter((filter) => filter.filterValue.value!!).map((filter) => ({ field: filter.field, value: filter.filterValue.value })), widget }),
      });
      if (response?.ok) {
        const newWidget = Object.assign(new Widget(), await response.json());
        const dashboard = this.dashboard.value;
        if (!dashboard) {
          return false;
        }
        const widgets = dashboard.widgets;
        if (!widgets) {
          return false;
        }

        dashboard.widgets = [...widgets, newWidget];
        this.dashboard.next(dashboard);
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isSavingWidget.next(false);
    }
  };

  updateWidget = async (widget: Widget) => {
    try {
      this.isSavingWidget.next(true);

      const response = await authService.authFetch("/dashboards/" + this.dashboard.value!!.id + "/widget", {
        method: "POST",
        body: JSON.stringify({ filters: this.filters.value!.filter((filter) => filter.filterValue.value!!).map((filter) => ({ field: filter.field, value: filter.filterValue.value })), widget }),
      });
      if (response?.ok) {
        const newWidget = Object.assign(new Widget(), await response.json());
        const dashboard = this.dashboard.value;
        if (!dashboard) {
          return false;
        }
        const widgets = dashboard.widgets;
        if (!widgets) {
          return false;
        }

        const widgetIndex = widgets?.findIndex((f) => f.id === widget.id);
        if (widgetIndex > -1) {
          widgets!![widgetIndex] = newWidget;
          dashboard.widgets = [...widgets];
          this.dashboard.next(dashboard);
          return true;
        }
        return false;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isSavingWidget.next(false);
    }
  };

  deleteWidget = async (id: string) => {
    const dashboard = this.dashboard.value;
    if (!dashboard) {
      return;
    }
    const widgets = dashboard.widgets;
    if (!widgets) {
      return;
    }

    dashboard.widgets = widgets?.filter((widget) => widget.id !== id);
    this.dashboard.next(dashboard);
  };

  getValues = async (filters: Filter[]) => {
    try {
      this.isFetchingValues.next(true);

      const dashboard = this.dashboard.value!;

      const response = await authService.authFetch("/dashboards/" + dashboard.id + "/widgets", {
        method: "POST",
        body: JSON.stringify({
          filters: filters.filter((filter) => filter.filterValue.value!!).map((filter) => ({ field: filter.field, value: filter.filterValue.value }))
        }),
      });
      if (response?.ok) {
        const dashboard = this.dashboard.value!!;
        dashboard.widgets = ((await response.json()) as Widget[]).map((x) => Object.assign(new Widget(), x));
        this.dashboard.next(dashboard);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingValues.next(false);
    }
  };

  getDashboardTemplates = async () => {
    try {
      this.isFetchingTemplates.next(true);

      const response = await authService.authFetch("/dashboards/templates", {
        method: "GET",
      });
      if (response?.ok) {
        this.templates.next(
          ((await response.json()) as DashboardTemplate[]).map((x) =>
            Object.assign(new DashboardTemplate(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingTemplates.next(false);
    }
  };

  getDatasets = async () => {
    try {
      this.isFetchingDatasets.next(true);

      const response = await authService.authFetch("/dashboards/datasets", {
        method: "GET",
      });
      if (response?.ok) {
        this.datasets.next(
          ((await response.json()) as Dataset[]).map((x) =>
            Object.assign(new Dataset(), x)
          )
        );
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isFetchingDatasets.next(false);
    }
  };

  add = async (name: string, datasetId: string, templateId: string | undefined) => {
    try {
      this.isAdding.next(true);

      const response = await authService.authFetch("/dashboards", {
        method: "POST",
        body: JSON.stringify({ name, datasetId, templateId }),
      });
      if (response?.ok) {
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isAdding.next(false);
    }
    return false;
  }

  update = async () => {
    try {
      this.isUpdating.next(true);

      const dashboard = this.dashboard.value!!;

      const response = await authService.authFetch("/dashboards", {
        method: "PATCH",
        body: JSON.stringify({ id: dashboard.id, name: dashboard.name, widgets: dashboard.widgets, filters: dashboard.filters }),
      });
      if (response?.ok) {
        this.isEditing.next(false);
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isUpdating.next(false);
    }
    return false;
  }

  delete = async () => {
    try {
      this.isDeleting.next(true);

      const dashboard = this.dashboard.value!!;

      const response = await authService.authFetch("/dashboards/" + dashboard.id, {
        method: "DELETE"
      });
      if (response?.ok) {
        this.isEditing.next(false);
        this.getFavorites();
        return true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isDeleting.next(false);
    }
    return false;
  }
}