// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/normalize.css/normalize.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}
.center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-table-expanded-row {
  background-color: lavender;
}
.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/index.less"],"names":[],"mappings":"AAGA;;;EAGE,YAAA;EACA,SAAA;EACA,UAAA;AAAF;AAGA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAIA;EACE,0BAAA;AAFF;AAKA;EACE,iBAAA;EACA,cAAA;AAHF","sourcesContent":["@import \"normalize.css/normalize.css\";\n@import \"antd/dist/reset.css\";\n\nbody,\nhtml,\n#root {\n  height: 100%;\n  margin: 0;\n  padding: 0;\n}\n\n.center {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.ant-table-expanded-row {\n  background-color: lavender;\n}\n\n.ant-dropdown-menu {\n  max-height: 250px;\n  overflow: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
