import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Permission } from "@/models/enum/permission";
import { adminUserService, authService, roleService, userService } from "@/services";
import { gravatar } from "@/utils";
import { useObservable } from "@/utils/use-observable";
import Loader from "./loader";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "@/utils/password-input-strenght";
import { MfaSetup } from "@/models/mfa-setup";
import { AdminUser, User } from "@/models/user";

const { Title } = Typography;
const { Option } = Select;

export function UserForm({ id, account }: { id: string; account?: boolean }) {
  const [form] = Form.useForm();
  const user = useObservable(userService.user);
  const isFetching = useObservable(userService.isFetchingOne);
  const isUpdating = useObservable(userService.isUpdating);
  const isDeleting = useObservable(userService.isDeleting);
  const isTogglingStatus = useObservable(userService.isTogglingStatus);
  const roles = useObservable(roleService.roles);
  const claims = useObservable(authService.claims);
  const router = useNavigate();
  const manageUser = useObservable(authService.hasPermission(Permission.ManageUsers));
  const [t] = useTranslation();

  useEffect(() => {
    claims?.sub === id ? userService.getMe() : userService.getUser(id);

    if (!account) {
      roleService.getRoles();
    }

    return () => {
      userService.disposeUser();
    };
  }, [id, account, claims?.sub]);

  const onSubmit = ({
    name,
    email,
    role,
  }: {
    name: string;
    email: string;
    role: string;
  }) => {
    (account
      ? userService.updateMe(user!!.id, name, email, user!!.role.id)
      : userService.updateUser(user!!.id, name, email, role)
    ).then((response) => {
      if (response) {
        message.success(t("user.updated"), 4);
      }
    });
  };

  const onChangeStatus = () => {
    if (user) {
      (user.enabled
        ? userService.disableUser(user.id, false)
        : userService.enableUser(user.id, false)
      ).then((response) => {
        if (response) {
          message.success(t(`user.${user.enabled ? "disabled" : "enabled"}`), 4);
        }
      });
    }
  };

  const onDelete = () => {
    userService.deleteUser(id).then((response) => {
      if (response) {
        message.success(t("user.deleted"), 4);
        router("/settings/users");
      }
    });
  };

  const onLogout = () => {
    authService.logout();
  };

  if (!user || isFetching) return <Loader />;

  return (
    <Row justify="center">
      <div style={{ width: "350px" }}>
        <Row align="middle">
          <Avatar size={70} src={gravatar(user?.email, 200)}></Avatar>
          <Title level={3} style={{ margin: "8px 0px 0px 10px" }}>
            {user.name}
          </Title>
        </Row>
        <Divider />
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            initialValue={user.name}
            label={t("user.name")}
            name="name"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input disabled={!user.enabled} />
          </Form.Item>
          <Form.Item
            initialValue={user.email}
            label={t("user.email")}
            name="email"
            rules={[{ required: true, message: t("error.fieldRequired") }]}
          >
            <Input disabled={!user.enabled} />
          </Form.Item>
          {!account && (
            <Form.Item
              initialValue={user.role?.id}
              label={t("user.role")}
              name="role"
              rules={[{ required: true, message: t("error.fieldRequired") }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                disabled={!user.enabled}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {roles.map((role) => (
                  <Option key={role.id} value={role.id}>
                    {role.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {user.enabled && (
            <>
              {(account || manageUser) && (
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    loading={isUpdating}
                    htmlType="submit"
                  >
                    {t("user.save")}
                  </Button>
                </Form.Item>
              )}
              {claims?.sub !== id && manageUser && (
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    block
                    loading={isDeleting}
                    htmlType="button"
                    onClick={onDelete}
                  >
                    {t("user.delete")}
                  </Button>
                </Form.Item>
              )}
              {claims?.sub === id && (
                <>
                  <Divider />
                  <Form.Item>
                    <EnableMFAButton mfaEnabled={user.mfaEnabled} />
                  </Form.Item>
                  <Form.Item>
                    <ChangePasswordButton />
                  </Form.Item>
                  <Divider />
                  <Form.Item>
                    <Button danger block onClick={onLogout}>
                      {t("user.logout")}
                    </Button>
                  </Form.Item>
                </>
              )}
            </>
          )}
          {claims?.sub !== id && manageUser && (
            <>
              <Divider />
              <Button
                block
                type="primary"
                ghost={user.enabled}
                loading={isTogglingStatus}
                onClick={onChangeStatus}
              >
                {user.enabled ? "Disable User" : "Enable User"}
              </Button>
            </>
          )}
        </Form>
      </div>
    </Row>
  );
}

export function ChangePasswordButton() {
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [t] = useTranslation();

  const showChangePasswordModal = () => {
    setChangePasswordVisible(true);
  };

  const hideChangePasswordModal = () => {
    setChangePasswordVisible(false);
  };

  return (
    <>
      <Button block onClick={showChangePasswordModal}>
        {t("changePassword.title")}
      </Button>
      <ChangePassword visible={changePasswordVisible} onFinish={hideChangePasswordModal} />
    </>
  );
}

function ChangePassword(props: { visible: boolean, onFinish: () => void }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const onSubmit = ({
    password,
    newPassword,
  }: {
    password: string;
    newPassword: string;
  }) => {
    setLoading(true);
    authService.changePassword(password, newPassword).then((changed) => {
      setLoading(false);
      if (!changed) {
        message.success(t("changePassword.success"), 4);
        props.onFinish();
        form.resetFields();
      }
    });
  };

  const onCancel = () => {
    props.onFinish();
    form.resetFields();
  };

  return (
    <Modal
      open={props.visible}
      onCancel={onCancel}
      title={t("changePassword.title")}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("modal.new.cancel")}
        </Button>,
        <Button type="primary" block loading={loading} htmlType="submit">
          {t("changePassword.submit")}
        </Button>
      ]}
      width={400}
    >
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: t("error.fieldRequired") }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="newPassword"
          validateTrigger="onBlur"
          validateFirst={true}
          rules={[{
            validator: (_, value) => {
              return !!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g) ? Promise.resolve() : Promise.reject(new Error("Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"));
            }
          }]}
        >
          <PasswordInput />
        </Form.Item>
        <Form.Item
          label={t("changePassword.confirmNewPassword")}
          name="confirmPassword"
          dependencies={["newPassword"]}
          validateTrigger="onBlur"
          validateFirst={true}
          rules={[
            { required: true, message: t("error.fieldRequired") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("changePassword.error.passwordNotMatch")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export function EnableMFAButton(props: { mfaEnabled: boolean, admin?: boolean }) {
  const [visible, setVisible] = useState(false);

  const onClick = () => {
    if (props.mfaEnabled) {
      userService.mfaDisable().then(() => {
        if (props.admin) {
          adminUserService.user.next(Object.assign(new AdminUser(), adminUserService.user.value!!, { mfaEnabled: false }));
        } else {
          userService.user.next(Object.assign(new User(), userService.user.value!!, { mfaEnabled: false }));
        }
      });
    } else {
      setVisible(true);
    }
  };

  const hideModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Button block onClick={onClick}>
        {props.mfaEnabled ? "Disable Multi Factor Authentication" : "Enable Multi Factor Authentication"}
      </Button>
      <MFAForm visible={visible} onFinish={hideModal} admin={props.admin} />
    </>
  );
}

function MFAForm(props: { visible: boolean, onFinish: () => void, admin?: boolean }) {
  const [setup, setSetup] = useState<MfaSetup>();

  useEffect(() => {
    if (props.visible) {
      userService.mfaEnable().then((setup) => {
        setSetup(setup);
        if (props.admin) {
          adminUserService.user.next(Object.assign(new AdminUser(), adminUserService.user.value!!, { mfaEnabled: true }));
        } else {
          userService.user.next(Object.assign(new User(), userService.user.value!!, { mfaEnabled: true }));
        }
      });
    }
  }, [props.visible, props.admin]);

  return (
    <Modal
      open={props.visible}
      onCancel={props.onFinish}
      title={"MFA Setup"}
      width={400}
      destroyOnClose
      footer={[
        <Button key="cancel" onClick={props.onFinish}>
          Close
        </Button>,
      ]}
    >
      {!setup ?
        <Loader /> :
        <Row justify="center">
          <Typography.Text style={{ fontSize: "20px", textAlign: "center" }}>Scan the QR code below with you authenticator app</Typography.Text>
          <img src={setup.qrCode} alt="MFA QR Code" />
          <Divider>
            <Typography.Text>OR enter the code manually</Typography.Text>
          </Divider>
          <Typography.Text copyable>{setup.secret}</Typography.Text>
        </Row>
      }

    </Modal>
  );
}
