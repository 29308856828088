import { AggregateFunction } from "../enum/aggregate-function";
import { Align } from "../enum/align";
import { DataType } from "../enum/data-type";

export class TableSettings {
    columns!: ColumnSettings[];
    pagination!: PaginationSettings;
}

export class ColumnSettings {
    name!: string;
    aggregateFunction?: AggregateFunction;
    field!: string;
    width!: number;
    align: Align = Align.Left;
    dataType!: DataType;
    mask?: string;
    sortable?: boolean;
    nested?: boolean;
}

export class PaginationSettings {
    enabled: boolean = true;
    pageSize: number = 10;
}