export class RestoreTokenResponse {
  logged!: boolean;
  accessToken?: string;
}

export class LoginResponse {
  mfaOtpRequired?: boolean;
  accessToken?: string;
}

export class AccessTokenResponse {
  accessToken!: string;
}
