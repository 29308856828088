import { AggregateFunction } from "../enum/aggregate-function";

export class LineSettings {
  aggregateFunction!: AggregateFunction;
  x!: LineSerie;
  y!: LineSerie[];
}

export class LineSerie {
  name!: string;
  field!: string;
}