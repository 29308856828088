import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { authService } from "@/services";
import useQueryParams from "@/utils/use-query-params";
import { useEffect } from "react";
import Loader from "../components/loader";

export default function EnableAccountPage() {
  const router = useNavigate();
  const query = useQueryParams();

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      authService.enableAccount(token).then((response) => {
        if (response) {
          message.success("Account enabled successfully", 10);
          router("/login");
        }
      });
    } else {
      message.error("Not valid url", 4, () => {
        router("/login");
      });
    }
  }, [router, query]);

  return (
    <Loader />
  );
}
