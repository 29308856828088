import { AggregateFunction } from "../enum/aggregate-function";

export class PieSettings {
  aggregateFunction!: AggregateFunction;
  key!: PieSerie;
  value!: PieSerie;
}

export class PieSerie {
  name!: string;
  field!: string;
}