import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ChangePasswordPage from "@app/change-password";
import LoginPage from "@app/login";
import MainPage from "@app/main";
import ResetPasswordPage from "@app/reset-password";
import { authService } from "@/services";
import { useObservable } from "@/utils/use-observable";
import SettingsPage from "./main/settings";
import AdminSettingsPage from "./main/admin/settings";
import AccountPage from "./main/account";
import NotFoundPage from "./not-found";
import AdminUsersPage from "./main/admin/settings/users";
import AdminUserPage from "./main/admin/settings/users/user";
import AdminOrganizationsPage from "./main/admin/settings/organizations";
import AdminOrganizationPage from "./main/admin/settings/organizations/organization";
import UsersPage from "./main/settings/users";
import UserPage from "./main/settings/users/user";
import RolesPage from "./main/settings/roles";
import RolePage from "./main/settings/roles/role";
import OrganizationPage from "./main/settings/organization";
import DashboardPage from "./main/dashboards/dashboard";
import VendorCentralCredentialsPage from "./main/vendor-central-credentials";
import VendorCentralCredentialPage from "./main/vendor-central-credentials/vendor-central-credentials";
import IntegrationPage from "./main/integration";
import { Permission } from "@/models/enum/permission";
import DisputeInvoiceLogsPage from "./main/data/dispute-invoices/logs";
import RemittancesPage from "./main/data/remittances";
import RequestsPage from "./main/admin/requests";
import InvoicesPage from "./main/data/invoices";
import InvoicePage from "./main/data/invoices/invoice";
import Loader from "./components/loader";
import DisputeInvoiceSettingsPage from "./main/data/dispute-invoices/settings";
import RemittancePage from "./main/data/remittances/remittance";
import DashboardsPage from "./main/dashboards";
import BillingPage from "./main/billing";
import SelectOrganization from "./select-organization";
import EnableAccountPage from "./enable-account";

function App() {
  const logged = useObservable(authService.isLogged);
  const admin = useObservable(authService.isAdmin);
  const switchOrganization = useObservable(authService.isSelectOrganization);

  const manageAmazonVendorCentralCredentials = useObservable(
    authService.hasPermission(Permission.ManageAmazonVendorCentralCredentials)
  );
  const manageIntegration = useObservable(
    authService.hasPermission(Permission.ManageIntegration)
  );
  const manageInvoices = useObservable(
    authService.hasPermission(Permission.ManageInvoices)
  );
  const manageRemittances = useObservable(
    authService.hasPermission(Permission.ManageRemittances)
  );
  const manageDisputeInvoiceSettings = useObservable(
    authService.hasPermission(Permission.ManageDisputeInvoiceSettings)
  );
  const manageDisputeInvoiceLogs = useObservable(
    authService.hasPermission(Permission.ManageDisputeInvoiceLogs)
  );
  const manageBilling = useObservable(
    authService.hasPermission(Permission.ManageBilling)
  );

  if (
    admin === null ||
    logged === null
  ) {
    return <Loader />;
  }

  if (logged && (manageAmazonVendorCentralCredentials === undefined ||
    manageIntegration === undefined ||
    manageInvoices === undefined ||
    manageRemittances === undefined ||
    manageDisputeInvoiceSettings === undefined ||
    manageDisputeInvoiceLogs === undefined ||
    manageBilling === undefined ||
    switchOrganization === undefined
  )) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      {!logged ? (
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="enable-account" element={<EnableAccountPage />} />
          <Route path="change-password" element={<ChangePasswordPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      ) : (
        logged && switchOrganization ? (
          <Routes>
            <Route path="select-organization" element={<SelectOrganization />} />
            <Route path="*" element={<Navigate to="/select-organization" />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<MainPage />}>
              <Route path="login" element={<Navigate to="/" />} />
              <Route index element={<Navigate to="/dashboards" />} />

              <Route path="select-organization" element={<SelectOrganization />} />
              <Route path="dashboards">
                <Route index element={<DashboardsPage />} />
                <Route path="new" element={<div />} />
                <Route path=":id" element={<DashboardPage />} />
              </Route>
              {manageAmazonVendorCentralCredentials && (
                <>
                  <Route
                    path="vendor-central-credentials"
                    element={<VendorCentralCredentialsPage />}
                  />
                  <Route
                    path="vendor-central-credentials/:id"
                    element={<VendorCentralCredentialPage />}
                  />
                </>
              )}
              {manageIntegration && (
                <Route path="integration" element={<IntegrationPage />} />
              )}
              {manageInvoices && (
                <>
                  <Route path="invoices" element={<InvoicesPage />} />
                  <Route
                    path="invoices/:payeeCode/:number"
                    element={<InvoicePage />}
                  />
                </>
              )}
              {manageRemittances && (
                <>
                  <Route path="remittances" element={<RemittancesPage />} />
                  <Route
                    path="remittances/:number"
                    element={<RemittancePage />}
                  />
                </>
              )}
              {(manageDisputeInvoiceSettings || manageDisputeInvoiceLogs) && (
                <Route path="dispute-invoices">
                  {manageDisputeInvoiceSettings && (
                    <Route
                      path="settings"
                      element={<DisputeInvoiceSettingsPage />}
                    />
                  )}
                  {manageDisputeInvoiceLogs && (
                    <Route path="logs" element={<DisputeInvoiceLogsPage />} />
                  )}
                </Route>
              )}
              {manageBilling && (
                <Route path="billing" element={<BillingPage />} />
              )}
              <Route path="settings" element={<SettingsPage />}>
                <Route index element={<Navigate to="users" />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="users/:id" element={<UserPage />} />
                <Route path="roles" element={<RolesPage />} />
                <Route path="roles/:id" element={<RolePage />} />
                <Route path="organization" element={<OrganizationPage />} />
              </Route>
              {admin && (
                <Route path="admin">
                  <Route path="requests" element={<RequestsPage />} />
                  <Route path="settings" element={<AdminSettingsPage />}>
                    <Route index element={<Navigate to="users" />} />
                    <Route path="users" element={<AdminUsersPage />} />
                    <Route path="users/:id" element={<AdminUserPage />} />
                    <Route
                      path="organizations"
                      element={<AdminOrganizationsPage />}
                    />
                    <Route
                      path="organizations/:id"
                      element={<AdminOrganizationPage />}
                    />
                  </Route>
                </Route>
              )}
              <Route path="account" element={<AccountPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        )
      )}
    </BrowserRouter>
  );
}

export default App;
